export function _params(state = [], action) {
  switch (action.type) {
    case "set-params":
      state = action.payload
      break;
    default:
  }
  return state;
}
export function _active_tab(state = [], action) {
  switch (action.type) {
    case "set-active-tab":
      state = action.payload
      break;
    default:
  }
  return state;
}

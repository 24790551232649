import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import './App.scss'

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))


const Login = React.lazy(() => import('./views/Pages/Login'))

class App extends React.Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {this.props._user.user_code === undefined ?
              <Route path="/" name="Login Page" render={props => <Login {...props} />} />
              :
              <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
            }
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

const mapStatetoProps = (state) => {
  return {
    _user: state._user
  }
}

export default connect(mapStatetoProps)(App)
import { combineReducers } from 'redux';

import { _user, _permisson, _player } from './user.reducer';
import { _SALE_ORDER, _SALE_ORDER_LIST, _SALE_ORDER_LIST_UPDATE, _SALE_ORDER_LISTS, _SALE_ORDER_DETAIL, _JOB_BY } from './sale-order.reducer';
import { _DATESTART, _DATEEND } from './date-filter.reducer';
import { _params, _active_tab } from './params.reducer';
import { _purchase_order_lists } from './purchase.reducer';

const rootReducer = combineReducers({
  _user,
  _permisson,
  _player,
  _SALE_ORDER,
  _SALE_ORDER_LIST,
  _SALE_ORDER_LIST_UPDATE,
  _SALE_ORDER_LISTS,
  _SALE_ORDER_DETAIL,
  _JOB_BY,
  _DATESTART,
  _DATEEND,
  _params,
  _active_tab,
  _purchase_order_lists
})

export default rootReducer
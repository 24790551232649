import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


import { store } from './_helpers';
import { Provider } from 'react-redux';
/* store.subscribe(() => {
   
}); */


import { saveState } from './_helpers';
/* configureFakeBackend(); */


store.subscribe(() => {

    saveState(store.getState())
})

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));


export function _user(state = [], action) {
  switch (action.type) {
    case "setuserlogout":
      state = []
      break;
    case "setUserLogin": 
      state = action.payload
      break;
    default:
  }
  return state;
}

export function _permisson(state = [], action) {
  switch (action.type) {
    case "clearPermission":
      state = []
      break;
    case "setPermission": 
      state = action.payload
      break;
    default:
  }
  return state;
}

export function _player(state = [], action) {
  switch (action.type) {
    case "clearplayerid":
      state = []
      break;
    case "setplayerid": 
      state = action.payload
      break;
    default:
  }
  return state;
}
export function _SALE_ORDER(state = [], action) {
  switch (action.type) {
    case "sale-order-data":
      state = action.payload
      break
    case "clear-sale-order":
      state = []
      break;

    default:
  }
  return state;
}

export function _SALE_ORDER_LIST(state = [], action) {
  switch (action.type) {
    case "set-sale-order-list":
      state = action.payload
      break;
    case "clear-sale-order_list":
      state = []
      break;
    default:
  }
  return state;
}

export function _SALE_ORDER_LIST_UPDATE(state = [], action) {
  switch (action.type) {
    case "set-sale-order-list-update":
      state = action.payload
      break;
    default:
  }
  return state;
}

export function _SALE_ORDER_LISTS(state = [], action) {
  switch (action.type) {
    case "set-sale-order-lists":
      state = action.payload
      break;
    case "push_sale_order_lists":
      state.push(action.payload)
      break;
    case "clear-sale-order_lists":
      state = []
      break;
    default:
  }
  return state;
}

export function _SALE_ORDER_DETAIL(state = [], action) {
  switch (action.type) {
    case "set-sale-order-detail":
      state = action.payload
      break;
    default:
  }
  return state;
}

export function _SALE_ORDER_DETAIL_LIST(state = [], action) {
  switch (action.type) {
    case "set-sale-order-detail-list":
      state = action.payload
      break;
    default:
  }
  return state;
}




export function _JOB_BY(state = [], action) {
  switch (action.type) {
    case "push_job_by":
      state.push(action.payload)
      break;
    case "clear_job_by":
      state = []
      break;
    case "update_job_by":
      state = action.payload
      break;

    default:
  }
  return state;
}




///------------------------UPDATE---------------------
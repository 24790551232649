export function _DATESTART(state = '', action) {
  switch (action.type) {
    case "set-date-start": 
      state = action.payload
      break;
    default:
  }
  return state;
}
export function _DATEEND(state = '', action) {
  switch (action.type) {
    case "set-date-end": 
      state = action.payload
      break;
    default:
  }
  return state;
}


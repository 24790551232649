export function _purchase_order_lists(state = [], action) {
  switch (action.type) {
    case "set-purchase-order-lists":
      state = action.payload
      break;
    case "clear-purchase-order-lists":
      state = []
      break;
    default:
  }
  return state;
}

